import './App.css';
import useWebSocket from 'react-use-websocket';
import { useEffect, useState } from "react";
import { Switch } from "@blueprintjs/core";


function App() {
    const socketUrl = "wss://ws-feed.exchange.coinbase.com";
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl);

    const [price, setPrice] = useState({});

    const [messageCount, setMessageCount] = useState(0);

    const [realtime, setRealtime] = useState(false);

    const debug = true;

    // subscribe and unsubscribe
    useEffect( () => {
        console.log(readyState);
        if(readyState === 1){

            const productIds =  [
                "ETH-USD",
                "BTC-USD",
                "SKL-USD",
                "BCH-USD"
            ];

            const subscribe = {
                "type": "subscribe",
                "product_ids": productIds,
                "channels": [
                    {
                        "name": realtime ? "ticker": "ticker_batch",
                        "product_ids": productIds
                    }
                ]
            };

            sendJsonMessage(subscribe);
            const unsubscribe = {...subscribe, type: 'unsubscribe'};
            return () => {
                console.log({unsubscribe});
                sendJsonMessage(unsubscribe);
            };
        }
        return () => {}
    }, [readyState, sendJsonMessage, realtime]);

    useEffect(() => {
        if (lastJsonMessage && lastJsonMessage){
            const packet = lastJsonMessage;

            if (packet['type'] === 'ticker'){
                setPrice(prevState => {

                    const newObj = {...prevState, [packet.product_id]: [ packet.price, packet.best_bid, packet.best_ask] };
                    return newObj;
                });
            }
            setMessageCount(prev => prev + 1);
        }
    }, [lastJsonMessage]);

    return (
        <div className="App">
            {
                Object.entries(price).sort().map(pair =>
                    <div key={pair[0]} className="currency-box">
                        <b>{pair[0]}</b>:&nbsp;
                        <span className="price">${pair[1][0]}</span>
                        <div className="details">
                            <div className="bid">Bid: <span className="price"> ${pair[1][1]}</span> </div>
                            <div className="ask">Ask: <span className="price">${pair[1][2]}</span> </div>
                        </div>
                    </div>
                )
            }

            <Switch checked={realtime} label="Realtime" onChange={e => setRealtime(prev => !prev)} />
            { debug && <div> Messages: {messageCount} </div> }
        </div>
    );
}

export default App;
